.filters-scroll {
  background-color: @filter-scroll-background;
  padding: 10px 20px;
  display: block;
  a {
    cursor: pointer;

    &.backward {
      width: 13px;
      height: 16px;
      background-image: url(../../img/filter-scroll-back.png);
    }
    &.forward {
      width: 13px;
      height: 16px;
      margin-left: 13px;
      background-image: url(../../img/filter-scroll-forw.png);
    }
    &.disabled {
      background-image: none;
    }
  }
}
.arrows-container {
  position: relative;
  display: flex;
}
