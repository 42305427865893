.filter-bar {
  height: 44px;
  background-color: @filter-bar-background;
  border-bottom: 4px solid @chalky;
}

.filter {
  background-color: @filter-item-background;
  overflow: visible;
  width: 200px;
  border-right: 1px solid @filter-item-border-right-color;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  &:hover {
    background-color: @filter-item-hover-background;
    color: @filter-item-hover-color;
  }

  &.active {
    background-color: @filter-item-hover-background;
  }

  .filter-name {
    .ellipsis;
    line-height: 40px;
    color: @filter-item-text-color;
    text-transform: uppercase;
    padding: 0 10px;
    padding-right: 0px;
    display: block;
  }
  .count {
    padding-right: 10px;
    line-height: 40px;
    font-weight: 600;
    color: @filter-item-count-color;
    display: block;

    &::after {
      content: '';
      width: 0px;
      height: 0px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid @chalky;
      position: absolute;
      right: 15px;
      bottom: 0px;
    }
  }
}
