.text-note-window {
  .modal-content {
    max-height: 80vh;
  }

  .modal-dialog {
    margin: 30px auto 0 auto;

    .close {
      opacity: 1;
      position: absolute;
      right: 2px;
      top: 20px;
      z-index: 1000;

      i {
        color: #000;
        font-size: 35px;
      }
    }
  }

  .modal-body {
    overflow-y: auto;
    padding: 0px;
  }

  .art-notice .notice-body, .art-notice .notice-head .notice-links {
    border-left: none;
  }
}

.slides {
  &.modal {
    text-align: center;
    padding: 0 !important;

    &::before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -4px;
    }
  }

  .modal-body:hover {
    .paginate {
      visibility: visible;
    }
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }

  .modal-content {
    background-color: transparent;
    box-shadow: none;
    border: none;
  }

  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0;
    .image-container {
      img {
        max-height: 85vh;
        max-width: 1070px;
        width: auto;
        height: auto;
        border: 35px solid #fff;
        border-bottom: 20px solid #fff;
      }
      div {
        width: 100%;
        text-align: center;
        background-color: #fff;
        padding: 10px;
      }
    }

    a {
      i {
        color: black;
        font-size: 40px;
      }

      &.close {
        align-self: flex-start;
        right: 35px;
        top: -20px;
        position: relative;
        opacity: 1;

        &.last {
          right: 0;
        }
      }

      &.paginate {
        position: relative;
        visibility: hidden;

        &.next {
          right: 36px;
        }

        &.prev {
          left: 36px;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .slides {
    .modal-content {
      width: 900px !important;
    }

    .modal-dialog {
      min-width: 900px !important;
    }

    &.modal {
      .modal-body {
        .image-container {
          img {
            width: 100%;
            min-height: auto;
          }
        }
      }
    }
  }
}

@media (max-width: 910px) {
  .slides {
    .modal-content {
      width: 600px !important;
    }

    .modal-dialog {
      min-width: 600px !important;
    }

    &.modal {
      left: 0 !important;
      &.modal {
        .modal-body {
          .image-container {
            img {
              width: 100%;
              min-height: auto;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 615px) {
  .slides {
    .modal-content {
      width: 350px !important;
    }

    .modal-dialog {
      min-width: 350px !important;
    }

    &.modal {
      left: 0 !important;
    }

    a.close {
      position: absolute !important;
      top: 0px !important;
    }
  }
}


@media (max-width: 768px) {
  .text-note-window {
    .modal-body {
      padding: 35px;
    }
  }

  .modal-dialog {
    max-width: 100% !important;
    min-width: initial;
  }
}
