.toast-container { 
  & > div.toast {
    background-image: none !important;
    background-color: #2b2b2b;
    padding: 15px 5px;
    color: white;
    font-weight: 300;
    font-size: 16px;
    border-radius: 50px;
    // position: fixed;
    width: 800px;
    opacity: inherit;

    .toast-message {
      display: block;
      padding: 0px 50px;

      &:after {
        font-family: FontAwesome;
        content: '\f00d';
        position: absolute;
        right: 25px;
        top: 15px;
      }
    }
  }
}

#media-breakpoint-down(
  sm, {
    .toast-container > div.toast {
      width: 100%;
    }
  });