.object-backdrop {
  &.fade {
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
  }

  &.in {
    opacity: 0.5;
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
  }
}

.object-share {
  &.opened {
    display: block;
  }

  h2 {
    font-size: 20px;
    font-weight: bold;
    color: @share-text-color;
  }

  .modal-body {
    padding: 25px;
  }

  .share-context {
    margin-bottom: 10px;
  }

  .share-url {
    .ellipsis;
    margin: 0px;
    border: 4px solid @share-url-border;
    position: relative;
    padding: 5px;
    font-size: 15px;
    margin-bottom: 10px;
    color: @share-text-color;
  }

  .share-buttons {
    margin: 0;
    margin-bottom: 10px;
    padding-left: 0px;
    li {
      color: white;
      padding: 4px;
      display: inline-block;
      width: 32px;
      text-align: center;
      cursor: pointer;
    }

    .facebook-box {
      background-color: #3b5998;
    }
    .twitter-box {
      background-color: #00acee;
    }
    .pinterest-box {
      background-color: #e20020;
    }

    a {
      color: white;
      //font-size: 2em;
      &:hover {
        color: #efefef;
      }
    }
  }
}
