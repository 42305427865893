.art-scroll-container {
  position: relative;
  width: 100%;

  .counter {
    max-width: 10%;
    background-image: url('../../img/art-scroll-counter-bg.png');
    background-position: left;
    background-repeat: no-repeat;
    text-align: right;
    padding-left: 20px;
    height: 100px;
    line-height: 100px;
  }
}
.art-scroll {
  position: relative;
    margin: 0 auto 0 15px;
    padding: 0;
    height: 120px;
    width: auto;
}
.art-scroll-content {
  max-width: 90%;
  overflow-y: hidden;
  white-space: nowrap;

  height: 120px;
  padding-bottom: 10px;
  padding-top: 10px;
  .item {
    display: inline-block;
    max-height: 67px;
    padding-right: 11px;
    opacity: .25;
    filter: ~"alpha(opacity = 25)";
    padding-bottom: 12px;
    cursor: pointer;
    position: relative;

    img {
      max-width: 45px;
      max-height: 65px;
    }

    &.active {
      opacity: 1;
      filter: ~"alpha(opacity = 100)";
      position: relative;

      .icon {
        width:100%;
        position:absolute;
        height: 12px;
        padding-right: 11px;
        line-height: 12px;
        font-size: 30px;
        color: @thumbnails-active-icon-color;
      }
    }

    i.icon.icon-tri_up {
      position: absolute;
      bottom: -5px;
      left: 0;
    }

    .thumb-icon {
      display: inline-block;
      width: 40px;
      height: 55px;
      padding-top: 15px;
      background-color: @thumbnails-icon-background;
      vertical-align: middle;

      .media-icon {
        position: relative;
      }
    }
  }
}

.scroller {
  width: auto;
  overflow-x: hidden;
  position: relative;

  td:last-child {
    padding-right: 50px;
  }
}

.art-scroll-content,
.art-scroll-container .counter {
  float: left;
}

