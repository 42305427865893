@charset "utf-8";
/* CSS Document */

@font-face {
  font-family: 'NAV3_font_V4';
  src: url('../font/NAV3_font_V4.eot');
  src: url('../font/NAV3_font_V4.eot') format('embedded-opentype'), url('../font/NAV3_font_V4.ttf') format('truetype'),
    url('../font/NAV3_font_V4.woff') format('woff'), url('../font/NAV3_font_V4.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'],
a[rel]:after,
.glyphicon {
  font-family: 'NAV3_font_V4';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 21px;
  text-align: center;
  line-height: 32px;
  color: #c6c6c6;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.accordion-item [class^='icon-'] {
  line-height: 1;
  margin: 0 10px -3px 0;
  float: left;
}
.accordion-item .arrow [class^='icon-'],
.accordion-item .arrow [class*=' icon-'] {
  color: #767676;
  font-size: 18px;
  line-height: 1;
}
a:hover [class^='icon-'],
a:hover [class*=' icon-'] {
  color: #ebcc89;
}
.notice-unfolded [class^='icon-'],
.notice-unfolded [class*=' icon-'] {
  color: #ebcc89;
  width: 34px;
  height: 34px;
  position: absolute;
  right: 0;
}
.filter-content .details [class^='icon-'],
.filter-content .details [class*=' icon-'] {
  position: absolute;
  left: 10px;
  line-height: 1;
}
.top-tools [class^='icon-'],
.top-tools [class*=' icon-'] {
  font-size: 24px;
  line-height: 24px;
}
.icon-not-available [class^='icon-'],
.icon-not-available [class*=' icon-'] {
  font-size: 104px;
}
.filter.thematic-dossier [class^='icon-'],
.filter.thematic-dossier [class*=' icon-'] {
  float: left;
  color: #ebcc89;
  margin-left: 10px;
  line-height: 42px;
}

.notice-close [class^='icon-'],
.notice-close [class*=' icon-'] {
  color: #767676 !important;
  font-size: 27px;
}
.open-all [class^='icon-'],
.open-all [class*=' icon-'] {
  color: #767676;
  line-height: 12px;
  font-size: 18px;
}
.notice-scroll [class^='icon-'],
.notice-scroll [class*=' icon-'] {
  color: #e5e5e5;
  font-size: 54px;
  line-height: 45px;
}
.notice-window [class^='icon-'],
.notice-window [class*=' icon-'] {
  font-size: 36px;
}
.notice-window .close-window [class^='icon-'],
.notice-window [class*=' icon-'] {
  color: #2b2b2b;
  line-height: 46px;
}
.notice-window .art-pagination [class^='icon-'],
.notice-window .art-pagination [class*=' icon-'] {
  line-height: 46px;
}
.filter-group.work-type .filter-item [class^='icon-'],
.filter-group.work-type .filter-item [class*=' icon-'] {
  font-size: 24px;
  position: relative !important;
  line-height: 8px;
  margin-right: 10px;
  color: #2b2b2b;
}
.filter-group.work-type .filter-item.unfolded [class^='icon-'],
.filter-group.work-type .filter-item.unfolded [class*=' icon-'] {
  color: #ebcc89;
}
.filter-group .pagination [class^='icon-'],
.filter-group .pagination [class*=' icon-'] {
  color: #000;
  font-size: 24px;
  line-height: 18px;
}

.icon-refresh_hit_result_map {
  color: #000;
  line-height: 12px;
}

.icon-close_hit_result_map {
  position: relative;
  top: 4px;
  line-height: 12px;
  color: #767676;
}

.icon-Nav3_id {
  font-size: 40px;
  color: #757575;
}

.icon-expand_PANEL {
  font-size: 30px;
  color: #757575;
}

.icon-refresh_zoom,
.icon-Zoom_previous {
  font-size: 40px;
  color: #000;
}

.icons [class^='icon-'] {
  color: #ebcc89;
}


.icon-expand_viewPORT:before {
  content: "\e900";
}
.icon-ADD_USER:before {
  content: "\e901";
}
.icon-Audio:before {
  content: "\e902";
}
.icon-bibliographie:before {
  content: "\e903";
}
.icon-clef_Gecol:before {
  content: "\e904";
}
.icon-CLOSE:before {
  content: "\e905";
}
.icon-dossier_thema:before {
  content: "\e906";
}
.icon-expositions:before {
  content: "\e907";
}
.icon-faq:before {
  content: "\e908";
}
.icon-favoris_off:before {
  content: "\e909";
}
.icon-favoris_on:before {
  content: "\e90a";
}
.icon-fleche_collapse_back:before {
  content: "\e90b";
}
.icon-fleche_collapse:before {
  content: "\e90c";
}
.icon-fleche_collpase:before {
  content: "\e90d";
}
.icon-forms:before {
  content: "\e90e";
}
.icon-historique:before {
  content: "\e90f";
}
.icon-Home:before {
  content: "\e910";
}
.icon-ICON_Link_Notice_OFF:before {
  content: "\e911";
}
.icon-ICON_Link_Notice_ON:before {
  content: "\e912";
}
.icon-img_pas_dispo:before {
  content: "\e913";
}
.icon-imprimer:before {
  content: "\e914";
}
.icon-Link:before {
  content: "\e915";
}
.icon-list_moins:before {
  content: "\e916";
}
.icon-list_plus:before {
  content: "\e917";
}
.icon-list_suite:before {
  content: "\e918";
}
.icon-moncompte:before {
  content: "\e919";
}
.icon-Next:before {
  content: "\e91a";
}
.icon-Notice_Add_Notes:before {
  content: "\e91b";
}
.icon-notice_close:before {
  content: "\e91c";
}
.icon-Notice_comments:before {
  content: "\e91d";
}
.icon-Notice_New:before {
  content: "\e91e";
}
.icon-notice:before {
  content: "\e91f";
}
.icon-outils:before {
  content: "\e920";
}
.icon-partager:before {
  content: "\e921";
}
.icon-PDF:before {
  content: "\e922";
}
.icon-PENCIL:before {
  content: "\e923";
}
.icon-Play:before {
  content: "\e924";
}
.icon-PREVIOUS:before {
  content: "\e925";
}
.icon-See_All_Filters_FilT_plus:before {
  content: "\e926";
}
.icon-See_All_Filters_FilT:before {
  content: "\e927";
}
.icon-See:before {
  content: "\e928";
}
.icon-SEND:before {
  content: "\e929";
}
.icon-Share_intern:before {
  content: "\e92a";
}
.icon-TOOLS:before {
  content: "\e92b";
}
.icon-trash:before {
  content: "\e92c";
}
.icon-tri_down:before {
  content: "\e92d";
}
.icon-tri_up:before {
  content: "\e92e";
}
.icon-up_down:before {
  content: "\e92f";
}
.icon-uvres_exposees:before {
  content: "\e930";
}
.icon-VALID:before {
  content: "\e931";
}
.icon-Video:before {
  content: "\e932";
}
.icon-vue_image_medium:before {
  content: "\e933";
}
.icon-vue_image_only:before {
  content: "\e934";
}
.icon-web_link:before {
  content: "\e935";
}
.icon-Nav3_id:before {
  content: "\e936";
}
.icon-Zoom_previous:before {
  content: "\e937";
}
.icon-expand_PANEL:before {
  content: "\e938";
}
.icon-arrow_panel:before {
  content: "\e939";
}
.icon-close_hit_result_map:before {
  content: "\e93a";
}
.icon-refresh_hit_result_map:before {
  content: "\e93b";
}
.icon-refresh_zoom:before {
  content: "\e93c";
}
.icon-unfold:before {
  content: "\e93d";
}
.icon-fold:before {
  content: "\e93e";
}
.icon-open-filter:before {
  content: "\e93f";
}
.icon-close-filter:before {
  content: "\e940";
}
.icon-mosaic:before {
  content: "\e941";
}
.icon-grid:before {
  content: "\e942";
}
.icon-box:before {
  content: "\e943";
}
.icon-boolean:before {
  content: "\e944";
}
