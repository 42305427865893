.ps--scrolling-y .ps__rail-y,
.ps--scrolling-x .ps__rail-x {
  background-color: #e6e6e6;
}

.ps__thumb-y {
  background-color: #757575;
  border-radius: 0px;
}

.ps__thumb-x {
  background-color: #757575;
  border-radius: 0px;
}
