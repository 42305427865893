.movement-sidebar {
  position: absolute;
  top: 0px;
  left: -420px;
  bottom: 0px;
  min-height: auto;
  width: 392px;
  border-top: 3px solid #efefef;
  background: white;
  transition: left 0.3s linear;
  z-index: 99;

  &:after {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 10px;
    visibility: hidden;
    transition: 0.1s ease-in;
    box-shadow: 8px 4px 17px rgba(0, 0, 0, 0.15);
    z-index: 100;
    content: '';
  }

  &.open {
    left: 0;

    &:after {
      visibility: visible;
    }

    .movement-toggle-button {
      .fa {
        transform: rotate(180deg);
      }
    }
  }

  &.marker-closed {
    left: -392px;
  }

  .movement-sidebar-inner {
    position: relative;
    height: 100%;
    overflow: auto;
    z-index: 999;
    padding-top: 20px;

    ul {
      padding: 0 20px;
    }

    .articles {
      li {
        padding: 10px 0;
        border-bottom: 1px solid #e0e0e0;

        h4 {
          margin: 0;
          font-weight: 600;
          font-size: 16px;
        }

        p {
          margin: 0;
          font-size: 12px;
        }

        &.location {
          .location-name {
            font-weight: 600;
            font-size: 16px;
          }
          .location-details {
            font-size: 14px;
            font-weight: 600;
          }
        }

        &.links {
          font-size: 12px;
        }
      }
    }

    .gallery {
      li {
        margin-bottom: 10px;

        img {
          max-height: 100px;
        }
      }
    }
    .header-gallery {
      li {
        font-size: 12px;
      }

      li:first-child {
        margin-bottom: 20px;
        height: 200px;
        line-height: 200px;
        vertical-align: middle;
        display: block;
        text-align: center;
      }

      li:last-child {
        margin-top: 20px;
        border-bottom: 1px solid #e0e0e0;
      }

      i {
        color: black;
        font-weight: bold;
      }

      img {
        max-width: 280px;
        max-height: 200px;
        margin: 0 auto;
        cursor: pointer;
      }
    }
    .total {
      margin-left: 20px;
      font-size: 12px;
    }

    .btn-navigart {
      padding: 0 20px;
      margin: 15px 0 20px 0;
      width: 95%;
      background: #000;
      color: white;
      text-align: left;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      line-height: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      &:hover {
        color: #ebcc89;
      }
    }

    hr {
      margin: 0 30px 30px 30px;
    }
    .links {
      text-decoration: underline;
    }

    .text-note-label {
      i {
        cursor: pointer;
        font-size: 25px;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .art-navigation.movement-pagination {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 100%;
    z-index: 1000;
    background-color: white;

    & + .movement-sidebar-inner {
      padding-top: 70px;
    }

    .pagination {
      width: 100%;
      margin: 13px;
      display: flex;
      justify-content: space-between;
      background-color: @art-navigation-link-background;
      a {
        z-index: 9999;
        &.next {
          border-left: 1px solid white;
        }

        &.prev {
          border-right: 1px solid white;
        }

        &:hover {
          color: #ffffff;
        }
      }
      .counter {
        flex-grow: 1;
      }
      span {
        text-transform: uppercase;
      }
    }
  }
}

.movement-toggle-button {
  position: absolute;
  width: 38px;
  height: 64px;
  top: 15px;
  padding: 4px;
  right: -38px;
  background: white;
  border: none;
  z-index: 99;
  box-shadow: 5px 0 5px rgba(0, 0, 0, 0.1);
  outline: none;

  span {
    font-size: 10px;
  }
}

.pagination-images {
  display: flex;
  justify-content: center;
  align-items: center;
  .prev {
    margin-right: 20px;
  }

  .next {
    margin-left: 20px;
  }
}
