.art-navigation {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;

  right: env(safe-area-inset-right); 
  bottom: env(safe-area-inset-bottom);

  a {
    width: 43px;
    height: 43px;
    display: block;
    background-color: @art-navigation-link-background;
    color: @art-navigation-link-color;
    text-align: center;

    &:active {
      background-color: @art-navigation-link-background;
    }

    &.disabled {
      background-image: url(../../img/not-available-bg1.png);
      cursor: default;

      i {
        color: @art-navigation-disable-link-color;
      }
    }

    i {
      font-size: 40px !important;
      line-height: 43px;
    }
  }

  &.artworks {
    flex-direction: column;

    a {
      width: auto;
    }

    a.prev i {
      display: inline-block;
      transform: rotate(90deg);
    }

    a.next i {
      display: inline-block;
      transform: rotate(90deg);
    }
  }

  .art-close {
    background-color: @art-navigation-close-link-background;
    color: @art-navigation-close-link-color;

    i {
      color: @art-navigation-close-link-color;
    }
  }

  span {
    height: 43px;
    display: block;
    background-color: @art-navigation-page-number-background;
    color: @art-navigation-page-number-color;
    text-align: center;
    padding: 0 10px;
    line-height: 43px;

    input {
      height: 20px;
      vertical-align: middle;
      line-height: 20px;
      text-align: center;
      background-color: @art-navigation-input-background;

      border: none;
      color: @art-navigation-input-color;
    }
  }

  &.light-navigation {
    a {
      background-color: @art-navigation-light-link-background;

      &.disabled {
        cursor: default;
        background-image: url(../../img/not-available-bg.png);

        i {
          color: @art-navigation-light-disable-link-color;
        }

        &:hover i {
          color: @art-navigation-light-disable-link-color;
        }
      }

      i {
        color: @art-navigation-light-link-color;
      }

      &:hover i {
        color: @art-navigation-light-link-color-hover;
      }
    }

    .art-close {
      color: @art-navigation-light-close-link-color;
      background-color: @art-navigation-light-close-link-background;

      i {
        color: @art-navigation-light-close-link-color;
      }
    }
  }
}
