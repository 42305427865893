.header,
.header-movements {
  //position: fixed;
  top: 0;
  width: 100%;
  background-color: @header-background-color;
  z-index: 101;

  &.innerScroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
}

.art-counter {
  color: @header-counter-color;
  padding-right: 30px;
  .BoldFont(40px);

  .total {
    color: @header-counter-total-color;
  }

  &::after {
    clear: both;
    display: block;
    content: '';
  }
}

.top-search {
  padding-top: 10px;

  .search-input-wrap {
    position: relative;
    clear: both;
    z-index: 999;
  }

  .search-actions {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    line-height: 34px;
    a {
      height: 34px;
      display: inline-block;
      padding-right: 10px;
      text-align: center;
      color: @searchbar-action-link-color;

      i {
        cursor: pointer;
      }

      &:hover {
        color: @searchbar-action-link-hover-color;
      }
    }
  }

  .search-input {
    width: 100%;
    border: 3px solid @searchbar-input-border-color;
    color: @searchbar-input-color;
    height: 34px;
    box-shadow: none;
    font-size: 20px;
    line-height: 20px;
    font-weight: 300;

    &::-webkit-input-placeholder {
      color: @searchbar-input-placeholder-color;
    }
    &:-moz-placeholder {
      color: @searchbar-input-placeholder-color;
    }
    &:-ms-input-placeholder {
      /* IE10+ */
      color: @searchbar-input-placeholder-color;
    }
    &.active {
      box-shadow: 0px 0px 20px 0px rgba(121, 121, 121, 0.5);
    }
  }

  .current-filters {
    margin: 0;
    background-color: @searchbar-current-filters-background;
    padding: 3px;
    margin-bottom: -3px;

    li {
      list-style: none;
      padding: 5px 5px 5px 10px;
      line-height: 20px;
      font-size: 20px;
      font-weight: 300;
      background-color: @searchbar-current-filters-item-background;
      color: @searchbar-current-filters-item-color;
      margin-bottom: 3px;
      position: relative;

      width: 100%;
      box-sizing: border-box;

      a {
        font-size: 18px;
        line-height: 20px;
      }

      .operator-or {
        padding: 0 5px;
      }

      .term-wrapper {
        display: flex;
        align-items: center;
      }

      .remove-all-wrapper {
        flex-grow: 1;
        align-self: flex-end;
        text-align: right;
      }

      a.remove-all-filter {
        font-size: 24px;
        i {
          color: @searchbar-remove-all-icon-color;
        }
        &:hover {
          i {
            color: @searchbar-editing-link-hover-color;
          }
        }
      }
      .term {
        strong {
          font-weight: 900;
        }
      }
      
      a.remove-filter {
        &:hover {
          i {
            color: @searchbar-editing-link-hover-color;
          }
        }
      }
    }
    li:last-child {
      margin-bottom: 0;
    }
    .remove-all-link {
      position: absolute;
      right: -110px;
      z-index: 100;
    }
  }
  i {
    color: @searchbar-icon-color;
  }
}
