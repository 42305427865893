.page404{
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #e5e5e5;

  h2 {
    text-align: center;
    margin: 0px 10px;
  }
}
