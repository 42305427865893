.notice-window {
  display: flex;
  border-top: 6px solid @notice-window-border;
  border-bottom: 6px solid @notice-window-border;
  background-color: @notice-window-background;
  padding: 10px;
  position: absolute;
  left: 60px;
  right: 60px;
  z-index: 1001;
  .RegularFont();
  line-height: 1.42857143;

  ul.details {
    list-style: none;
    padding-left: 0px;
    padding-right: 15px;

    li {
      background-color: @notice-window-details-background;
      border-bottom: 1px solid @notice-window-details-border;
      padding: 10px;
      font-size: 12px;
    }

    li:after {
      display: block;
      clear: both;
      content: "";
    }

    p {
      margin: 0px;
    }

    h2 {
      font-size: 16px;
      font-weight: 700;
      margin: 0px;
    }

    h3 {
      font-size: 16px;
      margin: 0px;
    }

    .more-details {
      color: @mine-shaft;
      font-size: 14px;
      .more-details-icon {
        padding-left: 6px;
        font-size: 26px;
        vertical-align: middle;
      }
    }
  }

  .art img {
    max-width: 100%;
  }

  .close-window {
    position: absolute;
    right: 0;
    top: -52px;
    text-align: center;
    height: 46px;
    width: 52px;
    display: block;
    background-color: @notice-window-close-window-background;
  }

  .art-pagination {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -62px;
    .next {
      background-color: @notice-window-pagination-background;
      width: 44px;
      height: 48px;
      text-align: center;
      display: block;
    }
    .prev {
      background-color: @notice-window-pagination-background;
      width: 44px;
      height: 48px;
      text-align: center;
      display: block;
    }
    .counter {
      margin-top: 2px;
      background-color: @notice-window-pagination-background;
      color: @notice-window-pagination-internal-font-color;
      font-size: 12px;
      widows: 44px;
      text-align: center;
      padding: 5px 0;
    }
  }

  .art-pagination-internal {
    position: absolute;
    right: 52px;
    top: -52px;
    height: 46px;
    line-height: 46px;
    color: @notice-window-pagination-font-color;
    background-color: @notice-window-close-window-background;
    i {
      color: @notice-window-pagination-font-color;
    }
    .next {
      width: 44px;
      height: 48px;
      text-align: center;
      display: block;
      float: left;
      padding: 6px;
    }
    .prev {
      width: 44px;
      height: 48px;
      text-align: center;
      display: block;
      float: left;
      padding: 6px;
    }
    .counter {
      font-size: 12px;
      display: block;
      float: left;
    }
  }
}
