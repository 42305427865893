
// Colors
// --------------------------------------------------

@cod-gray:                      #1d1d1d;
@silver:                        #c6c6c6;
@chalky:                        #ebcc89;
@black:                         #000000;
@boulder:                       #757575;
@tundora:                       #4d4d4d;
@wild-sand:                     #f6f6f6;
@mercury:                       #e5e5e5;
@alto:                          #d9d9d9;
@alto2:                         #d2d2d2;
@gallery:                       #ececec;
@dusty-gray:                    #9c9c9c;
@silver-calice:                 #adadad;
@prussian-blue:                 #002B47;
@mine-shaft:                    #2b2b2b;
@silver:                        #c8c8c8;
@white:                         #ffffff;
@black:                         #000000;
@green:                         #8CD58A;
@scorpion:                      #565656;
@sidecar:                       #F5E5C4;
@alabaster:                     #f7f7f7;
@golden-sand:                   #F1CC7E;
@alto3:                         #d6d6d6;
@silver-chalice:                #b2b2b2;
@sapling:                       #dfcaa4;
@tundora2:                      #454545;
@silver2:                       #cccccc;

// Global
// -------------------------

@default-text-color:                     @cod-gray;
@default-button-background:              @silver-calice;
@default-button-color:                   @white;
@default-link-color:                     @dusty-gray;
@default-link-hover-color:               @black;
@default-icon-play-color:                @gallery;
// Artworks
// -------------------------

// Grid Item

@grid-item-left-background:               @white;
@grid-item-right-background:              @white;
@grid-item-copyright-color:               @boulder;
@grid-item-work-type-color:               @boulder;
@grid-item-other-images-color:            @boulder;
@grid-item-separator-line:                @mercury;
@grid-item-details-ellipsis-background:   @white;

// Mosaic Item

@mosaic-item-background:                  @white;
@mosaic-item-play-color:                  @white;

// Artwork Lits

@artwork-list-background:                 @mercury;
@artwork-list-select-all-color:           @dusty-gray;


// Artwork
// -------------------------

@artwork-single-background:              @white;

// Accordion

@accordion-open-icon-color:               @chalky;
@accordion-open-icon-hover-color:         @chalky;
@accordion-data-link-background:          @chalky;
@accordion-data-link-color:               @prussian-blue;

// Main

@serif-text-color:                        @cod-gray;

// Navigation

@art-navigation-link-background:          @mine-shaft;
@art-navigation-link-color:               @mercury;
@art-navigation-close-link-background:    @mercury;
@art-navigation-close-link-color:         @mine-shaft;
@art-navigation-page-number-background:   @mine-shaft;
@art-navigation-page-number-color:        @mercury;

@art-navigation-input-color:              @chalky;
@art-navigation-input-background:         @scorpion;

@art-navigation-disable-link-color:       @mine-shaft;

@art-navigation-light-link-background:    @mercury;
@art-navigation-light-link-color:         @mine-shaft;
@art-navigation-light-link-color-hover:   @chalky;
@art-navigation-light-close-link-color:   @mercury;
@art-navigation-light-close-link-background:  @mine-shaft;


@art-navigation-light-disable-link-color:       @silver;
// Thumbnails

@thumbnails-active-icon-color:            @black;
@thumbnails-icon-background:              @mine-shaft;

// Sidebar

@artwork-sidebar-background:              @mercury;
@artwork-sidebar-color:                   @mine-shaft;
@artwork-sidebar-content-background:      @white;
@artwork-sidebar-tools-border:            @mercury;
@artwork-sidebar-tools-background:        @white;
@artwork-sidebar-details-border:          @mercury;
@artwork-notice-close-link-color:         @boulder;
@artwork-sidebar-icon-color:              @chalky;
@artwork-sidebar-active-border:           @chalky;
@artwork-sidebar-share-print-border:      @dusty-gray;

// Compression

@compression-background:                  @silver;
@compression-link-color:                  @cod-gray;
@compression-gap-number-background:       @alto;
@compression-number-background:           @white;
@compression-crew-content-background:     @white;
@compression-navigation-background:       @alto;
@compression-navigation-icon-color:       @mine-shaft;


// Filter Bar
// -------------------------

@filter-bar-background:                    @boulder;
@filter-item-background:                   @boulder;
@filter-item-border-right-color:           @white;
@filter-item-hover-background:             @chalky;
@filter-item-hover-color:                  @black;
@filter-item-active-background:            @chalky;
@filter-item-all-background:               @black;
@filter-item-text-color:                   @white;
@filter-item-count-color:                  @black;
@filter-item-all-count-color:              @white;
@filter-item-border:                       @alto;
@filter-item-odd-background:               @wild-sand;
@filter-item-link-color:                   @black;

// Filter Dropdown

@filters-dropdown-item-even:               @alabaster;
@filters-dropdown-item-hover:              @golden-sand;
@filters-dropdown-item-border:             @alto;
@filters-dropdown-header-border:           @boulder;
@filters-dropdown-icon-color:              @silver-chalice;
@filters-dropdown-icon-hover:              @sapling;
@filters-dropdown-icon-active:             @tundora2;

// Filter Content

@filter-content-border-top:                @chalky;
@filter-content-background:                @white;
@filter-content-search-input-border:       @alto;
@filter-content-search-input-hover:        @golden-sand;
@filter-content-sort-color:                @boulder;
@filter-content-group-border:              @mercury;
@filter-content-counter-total-color:       @silver;
@filter-content-counter-filtered-color:    @black;

// Filter Pagination
@filter-pagination-color:                   @silver;
@filter-pagination-strong-color:            @black;

//Filter Scroll

@filter-scroll-background:                  @tundora;


// Notice
// -------------------------

@notice-open-all-link-background:           @mercury;
@notice-open-all-link-color:                @mine-shaft;
@notice-body-color:                         @black;
@notice-body-more-background:               @chalky;
@notice-body-more-color:                    @black;
@notice-body-more-unfolded-background:      @mine-shaft;
@notice-body-more-unfolded-color:           @white;
@notice-body-underline-border-color:        @prussian-blue;
@notice-body-underline-text-color:          @prussian-blue;
@notice-body-scroll-background:             @mine-shaft;
@notice-body-resources-background:          @gallery;
@notice-body-resources-h2-color:            @mine-shaft;
@notice-body-resources-see-all-color:       @mine-shaft;
@notice-body-resources-border:              @gallery;
@notice-body-external-color:                @prussian-blue;
@notice-body-external-link-color:           @mine-shaft;
@notice-body-internal-list-color:           @chalky;
@notice-body-internal-link-color:           @mine-shaft;
@notice-body-internal-link-background:      @chalky;
@notice-body-border-left:                   @mine-shaft;

// Notice Preview

@notice-window-border:                      @mine-shaft;
@notice-window-background:                  @gallery;
@notice-window-details-border:              @gallery;
@notice-window-details-background:          @white;
@notice-window-more-details-background:     @white;
@notice-window-more-details-color:          @silver;
@notice-window-close-window-background:     @gallery;
@notice-window-pagination-background:       @mine-shaft;
@notice-window-pagination-font-color:       @mine-shaft;
@notice-window-pagination-internal-font-color: @white;

// Notice Edit

@valid-btn-color: @green;

// Searchbar
// -------------------------

@header-background-color:                   @white;
@header-counter-color:                      @black;
@header-counter-total-color:                @silver;

@searchbar-action-link-color:               @silver;
@searchbar-action-link-hover-color:         @black;
@searchbar-input-border-color:              @alto;
@searchbar-input-color:                     @black;
@searchbar-input-placeholder-color:         @silver;
@searchbar-current-filters-background:      @alto;
@searchbar-current-filters-item-background: @white;
@searchbar-current-filters-item-color:      @black;
@searchbar-icon-color:                      @silver2;
@searchbar-remove-all-icon-color:           @silver-chalice;
@searchbar-editing-link-color:              @silver-chalice;
@searchbar-editing-link-hover-color:        @golden-sand;

// Top tools

@top-tools-border:                          @mercury;
@top-tools-favs-hover-background:           @mine-shaft;
@top-tools-favs-hover-color:                @chalky;
@top-tools-favs-open-color:                 @mine-shaft;

// Top tools dropdown
@top-tools-dropdown-border:                                 @mine-shaft;
@top-tools-dropdown-background:                             @white;
@top-tools-dropdown-item-border-right:                      @white;
@top-tools-dropdown-item-border-bottom:                     @alto;
@top-tools-dropdown-item-hover-background:                  @mercury;
@top-tools-dropdown-item-hover-border-right:                @chalky;
@top-tools-dropdown-item-hover-first-child-background:      @chalky;
@top-tools-dropdown-item-hover-first-child-border-right:    @white;
@top-tools-dropdown-item-link-color:                        @mine-shaft;
@top-tools-dropdown-item-display-all-background:            @white;
@top-tools-dropdown-item-expanded-border-right:             @mercury;

@top-tools-dropdown-input-border:                           @chalky;
@top-tools-dropdown-input-button-bg:                        @mine-shaft;
@top-tools-dropdown-input-button-border:                    @top-tools-dropdown-input-button-bg;
@top-tools-dropdown-input-button-color:                     @white;

// Auth Form
// -------------------------
@auth-page-background:                                      @alto;
@login-form-background:                                     @white;
@login-form-footer-background:                              @alto2;
@login-form-submit-color:                                   @white;
@login-form-submit-background:                              @mine-shaft;
@login-form-social-media-color:                             @cod-gray;
@login-form-input-background:                               @gallery;
@login-form-forgot-password-color:                          @cod-gray;
@login-form-footer-link-color:                              @chalky;
@login-form-footer-link-background:                         @white;
// Share Modal
// -------------------------

@share-text-color:                                          @silver;
@share-url-border:                                          @alto;

// Tooltip
// -------------------------

@tooltip-bg:                                                @chalky;
@tooltip-color:                                             @mine-shaft;
@tooltip-arrow-color:                                       @tooltip-bg;

@rich-link-icon-color:                                      @silver;
@rich-link-border-color:                                    @silver;
@rich-link-hover-color:                                     @sidecar;

.drop-shadow(@x-axis: 0, @y-axis: 1px, @blur: 2px, @alpha: 0.1) {
  -webkit-box-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
  -moz-box-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
  box-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
}

.border-radius (@radius: 5px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  -ms-border-radius: @radius;
  -o-border-radius: @radius;
  border-radius: @radius;
}

.border-box {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.n-text-bold {
  font-weight: bold;
}

.n-text-italic {
  font-style: italic;
}

// was h2
.single-artwork-authors-ua {
  padding: 0px;
  font-size: 16px;
  font-weight: 700;
  color: @black;
  &:hover {
    color: @dusty-gray;
  }
}

// was h1
.single-artwork-title-ua {
  padding: 0px;
  font-size: 16px;
  font-weight: 700;
  font-style: italic;
}

a[rel="link"]::after {
  content: "\e935";
}

a[rel="pdf"]::after {
  content: "\e922";
}

a[rel="video"]::after {
  content: "\e932";
}

a[rel="audio"]::after {
  content: "\e902";
}

a[rel="link"] {
  color: @prussian-blue;
}

a[rel="pdf"] {
  color: @prussian-blue;
}

a[rel="video"] {
  color: @prussian-blue;
}

a[rel="audio"] {
  color: @prussian-blue;
}

.authors a[rel] {
  color: @prussian-blue;
}
.RegularFont(@size: 13px, @weight: 400) {
  font-size: @size;
  font-family: Lato, Lucida, Verdana, Arial, Helvetica, sans-serif;
  font-weight: @weight;
}

.BoldFont(@size: 13px) {
  font-size: @size;
  font-family: Lato, Lucida, Verdana, Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.BlackFont(@size: 13px) {
  font-size: @size;
  font-family: Lato, Lucida, Verdana, Arial, Helvetica, sans-serif;
  font-weight: 900;
}

.HeaderFont(@size: 15px) {
  font-size: @size;
  font-family: Cambria, 'Liberation Serif', Times, 'Times New Roman', serif;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &::after {
    content: '';
    display: block;
  }
}

.clickable {
  cursor: pointer;
}

.vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}





























































.toggle-operator {
  border-right: 3px solid @silver-chalice;
  padding: 0px !important;
  position: absolute;
  left: -35px;
  width: 30px;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  min-height: 20px;

  &:hover {
    border-right-color: @golden-sand;
    span {
      background-color: @golden-sand;
    }
  }

  span {
    background-color: @silver-chalice;
    font-size: 10px;
    line-height: 1.2;
    padding-left: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: white;
    text-transform: uppercase;
  }
}
