.introjs-tooltip {
  max-width: 400px;
  min-width: 300px;

  h2 {
    .BoldFont(18px);

    color: @boulder;
    line-height: 18px;
  }
}

.introjs-tooltip-right {
  right: 0px;
}

.introjs-helperNumberLayer {
  background: @chalky; /* Old browsers */
  background: -webkit-linear-gradient(top, @chalky 0%, @chalky 100%); /* Chrome10+,Safari5.1+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, @chalky),
    color-stop(100%, @chalky)
  ); /* Chrome,Safari4+ */
  background: -moz-linear-gradient(top, @chalky 0%, @chalky 100%); /* FF3.6+ */
  background: -ms-linear-gradient(top, @chalky 0%, @chalky 100%); /* IE10+ */
  background: -o-linear-gradient(top, @chalky 0%, @chalky 100%); /* Opera 11.10+ */
  background: linear-gradient(to bottom, @chalky 0%, @chalky 100%); /* W3C */
}

.introjs-button {
  background-image: none;
  background-color: #adadad;
  color: #ffffff;
  text-shadow: none;
  padding: 4px 12px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 0px;

  &:hover,
  &:focus {
    color: #333333;
    background-color: #e6e6e6;
    box-shadow: none;
  }
}

.introjs-disabled {
}
