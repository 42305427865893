.gm-style .gm-style-iw-t::after {display: none;}

.gm-style .gm-style-iw {
  padding: 20px 0 0 20px;
  font-size: 12px;
  max-width: 300px !important;
  left: 0 !important;
  top: 0 !important;
}

#popup {
  min-height: 245;
  min-width: 135;
  line-height: 1.3;

  .location {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: .5rem;
  }
  .city {
    font-size: 16px;
    font-weight: 600;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
  }

  .summarized {
    margin-top: .5rem;
    font-size: 10px;
  }
}
