.top-tools {
  & > div {
    float: right;
    border-left: 2px solid @top-tools-border;
    padding: 10px 10px 0 10px;
    overflow: visible;
    position: relative;

    & > a {
      height: 24px;
      display: block;
      background-repeat: no-repeat;
      background-position: top center;
    }
  }
  .history a {
    text-align: center;
  }
  a.active,
  button.active {
    i {
      color: black;
    }
  }
  a.grid-1 {
    text-align: center;
    float: left;
  }
  a.grid-2 {
    text-align: center;
    float: left;
    margin-left: 10px;
  }
  .language {
    padding-left: 0px;
    border-left: none;
    position: relative;

    text-transform: uppercase;

    & > a {
      width: 50px;
      padding-left: 5px;
      border: 2px solid @searchbar-input-placeholder-color;
      color: @searchbar-input-placeholder-color;
      &:hover {
        border-color: @chalky;
      }
    }

    i {
      position: absolute;
      top: 12px;
      right: 8px;
      line-height: 19px;
      pointer-events: none;
    }

    &.open {
      a {
        border-color: @chalky;
      }

      i {
        color: @chalky;
      }
    }
  }

  .favs {
    &:hover i {
      color: @top-tools-favs-hover-color;
    }
    &.open i.bar-favoris_on,
    i.star-black {
      color: @top-tools-favs-open-color;
    }
    & > a {
      text-align: center;
    }
    .count {
      text-align: center;
      font-size: 12px;
      position: absolute;
      top: 34px;
      left: 0;
      right: 0;
      padding: 2px 0;
    }
  }

  .quit-buttons {
    border-left: none;
    a {
      height: 36px;
    }
  }

  button.btn-link {
    padding: 0;

    &:hover,
    &:active {
      text-decoration: none;
    }
  }
}
