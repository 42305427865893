.filter-content {
  //border-top: 3px solid @filter-content-border-top;
  z-index: 999;
  display: block;
  position: absolute;
  //top: 40px;
  left: 0;
  right: 0;
  background-color: @filter-content-background;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
  padding: 0px 0px 10px 0px;
  min-height: 400px;

  .search {
    padding-bottom: 5px;
    padding: 15px 0px;

    .search-actions {
      position: absolute;
      top: 0px;
      right: 10px;
      bottom: 0px;
      line-height: 34px;
      color: @filter-content-search-input-border;
    }

    input[type='text'] {
      width: 100%;
      border: 4px solid @filter-content-search-input-border;
      box-shadow: none;
    }
  }
  label {
    margin-left: 20px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
    cursor: pointer;
  }

  input[type='text'] {
    width: 100%;
  }

  input[type="radio"] {
    cursor: pointer;
    padding: 0.2rem;
  }

  .sort {
    margin-left: 20px;
    font-size: 14px;
    text-transform: uppercase;
    padding: 5px;
    display: flex;
    justify-content: space-between;

    a {
      color: @filter-content-sort-color;
      padding-left: 17px;
      background-position: left;
      background-repeat: no-repeat;
      background-image: url(../../img/filter-sort-bg.png);

      &.asc {
        background-image: url(../../img/filter-sort-asc-bg.png);
      }

      &.desc {
        background-image: url(../../img/filter-sort-desc-bg.png);
      }
    }
  }
}

.tree-modal-close {
  background-color: @filter-content-search-input-border;
  width: 40px;
  height: 40px;
  cursor: pointer;
  i {
    position: absolute;
    top: 4px;
    left: 6px;
    font-size: 32px;
    color: @art-navigation-page-number-background;
  }
}

.tree-modal {
  border-top: none;
  padding: 3em 4em 1em 3em;
  height: 85vh;
  overflow: auto;
  box-shadow: none;
  top: 0px;
}

.resizerable-left {
  height: 80vh !important;
}

.resizerable-right {
  height: 80vh !important;
}

.single-filter {
  padding-left: 10px !important;
  padding-right: 10px !important;
  overflow: auto;
  &.tree {
    height: 554px;
  }

  &.no-tree {
    min-height: 400px;
    .filter-item {
      .filter-name {
        padding-left: 28px;
      }
      input[type='checkbox'] + .filter-name {
        padding-left: 0px;
      }
    }
  }
}

.all-filters-toolbar {
  text-align: right;
}

.all-filters {
  padding: 0;
  position: relative;
  left: 0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  .filter-group {
    width: 200px;
    border-right: 1px solid @filter-content-group-border;
    padding-top: 10px;
    float: left;
    min-height: 400px;
  }
  .filter-item {
    .filter-name {
      padding-right: 50px;
    }
  }
}
.filter-group-items {
  display: flex;
  border-top: 1px solid @filter-item-border;
}

.tree-search {
  display: flex;
  justify-content: space-between;

  .input-tree-search-wrap {
    position: relative;
    flex-grow: 1;
  }

  .tree-buttons {
    margin: 0 30px 0 30px;
    flex-grow: 0;
    .btn-round {
      background-color: @art-navigation-page-number-background;
      margin-left: 20px;
      color: @art-navigation-link-color;
      border: none;
    }
  }

  .expand {
    flex-grow: 0;
    width: 40px;
    height: 40px;
    background-color: @filter-item-border;
    position: relative;
    .expand-icon {
      cursor: pointer;
      position: absolute;
      left: 4px;
      top: 4px;
      width: 40px;
      height: 40px;
      a {
        i {
          color: @filter-item-background;
          font-size: 30px;
        }
      }
    }
  }
}
