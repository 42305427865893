.modal-dialog .icon-trash {
  font-size: 34px;
  line-height: 34px;
}

.modal-dialog {
  min-width: 900px;
}

.resizerable-left {
  cursor: col-resize;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 10px;
  z-index: 100;
}

.resizerable-right {
  cursor: col-resize;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  width: 10px;
  z-index: 100;
}

.modal-header {
  background-color: @mercury;

  .modal-title {
    font-weight: 100;
  }

  .btn-valid {
    background-color: @white;
    padding: 2px 15px;
    i {
      vertical-align: middle;
      font-size: 28px;
    }
    &.btn-valid-green {
      border-color: @valid-btn-color;
      i.icon-VALID {
        color: @valid-btn-color;
        font-size: 28px;
        position: absolute;
        margin: 0 -25px;
      }
    }
  }
}
.auto-grow.md-editor {
  margin-top: 8px;
  display: block;
  position: relative;
  margin-left: -10px;
  margin-right: -4px;
  z-index: 1;
  > .md-header,
  .md-footer {
    display: block;
    padding: 6px 4px;
    background-color: @black;
  }
  > .md-header {
    width: 407px;
    margin: 0 26px 0 10px;
    .btn-group > .btn {
      background-color: @black;
      font-weight: bold;
      color: @white;
      border: 0;
    }
  }
  > textarea {
    overflow: hidden;
    border: 8px solid @chalky;
    background-color: @white;
    padding: 65px 20px;
    color: @dusty-gray;
    display: block;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    min-height: 200px;
    &:focus {
      box-shadow: none;
      color: @mine-shaft;
      outline: 0;
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 55px;
    right: -17px;
    border-top: 17px solid transparent;
    border-left: 17px solid @chalky;
    border-bottom: 17px solid transparent;
    z-index: 999999;
  }
}
// .livepreview {
//   margin-left: -7px;
//   margin-right: -10px;
//   padding: 0 30px 30px;
//   border: 8px solid @mercury;
//   p {
//     word-wrap: break-word;
//   }
//   h2 {
//     margin-bottom: 30px;
//   }
//   .controls {
//     background-color: white;
//     padding: 8px 0;
//     z-index: 1;
//   }
//   .notice-thumb img {
//     padding-bottom: 30px;
//     max-width: 120px;
//   }

//   .notice-body {
//     padding: 0px;
//     line-height: 1.8;
//     .HeaderFont(15px);
//     font-weight: normal;
//   }
// }
.modal-notice-edit {
  overflow-y: hidden;
}

.modal-notice-edit,
.modal-parameters-edit {
  overflow-x: hidden;
}

.modal-notice-edit,
.modal-parameters-edit {
  .modal-body {
    .form-control[disabled] {
      background-color: #cecece;
      background-image: repeating-linear-gradient(
        -45deg,
        transparent,
        transparent 1px,
        rgba(255, 255, 255, 0.5) 2px,
        rgba(255, 255, 255, 0.5) 8px
      );
    }

    p.form-control[disabled] {
      height: 100%;
    }

    label {
      font-weight: normal;
      padding-right: 20px;
      text-align: right;
    }
    section {
      padding: 40px;
      .row;

      &.light {
        background-color: #efefef;
      }
      &.dark {
        background-color: #dfdfdf;
      }
    }

    .md-editor {
      margin-top: 48px;
      &::after {
        top: 15px;
      }
      .md-header {
        top: 98px;
        width: 407px;
        position: fixed;
      }
    }
    .notice-body {
      padding: 25px 0px;
    }
    .controls {
      position: fixed;
      width: 357px;
      top: 98px;
    }

    &.affix-background {
      .controls {
        top: 70px;
        padding-top: 36px;
      }

      .resizerable-fixed-md-header {
        background-color: white;
        height: 68px;
        top: 70px;
        left: 39px;
        width: 386px;
        position: fixed;
      }
    }
  }
}
.links-modal {
  .modal-header .btn {
    margin-left: 4px;
  }
  .modal-body {
    ol {
      padding: 0;
      li {
        counter-increment: mycounter;
        list-style-type: none;
        margin-bottom: 15px;
        &:before {
          content: counter(mycounter) '- ';
        }
        &.bracket:before {
          content: '[' counter(mycounter) ']- ';
        }
      }
    }
    ol.start {
      counter-reset: mycounter;
    }
    ol.continue {
      counter-reset: mycounter 3;
    }
  }
}
