.movements-container {
  height: 100vh;
  height: stretch;
  flex: 1 1 auto;
  position: relative;
}

.map {
  position: initial !important;
}

.previous-zoom,
.reset-zoom {
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0px 2px 1.72px 0.28px rgba(0, 0, 0, 0.4);
  text-align: center;
  margin-right: 10px;
}

.custom-control-div {
  color: rgb(25, 25, 25);
  font-size: 12px;
  padding-left: 3px;
  padding-right: 3px;
  text-align: center;
}

.previous-zoom,
.reset-zoom {
  .custom-control-div {
    width: 47px;
    height: 47px;
    i {
      position: relative;
      top: 8px;
    }
  }
}
