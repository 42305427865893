.item {
  display: block;

  &.odd {
    & > .checkbox {
      background-color: @filter-item-odd-background;
    }
  }
  &.last-item {
    border-bottom: 1px solid @filter-item-border;
  }
}

.tree-operator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    width: 2rem;
    cursor: pointer;
    &.icon-list_plus {
      color: @filter-item-background;
    }
    &.icon-list_moins {
      color: @filter-item-hover-background;
    }
  }
  .empty-nodes {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: @filter-content-sort-color;
    margin: 0 11px 0 10px;;
  }
}

.nodes {
  background-color: white;
}

.filter-item {
  background-position: left;
  background-repeat: no-repeat;
  font-size: 14px;
  color: @filter-item-link-color;
  margin-right: 1px;
  margin-left: -4px;
  position: relative;
  flex-grow: 1;

  &.no-tree {
    border-top: 1px solid @filter-item-border
  }

  input[type="checkbox"] {
    float:left;
    margin:8px;
  }

  label {
    cursor: pointer;
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .filter-name {
    padding: 5px 0;
    background-repeat:no-repeat;
    color: @filter-item-link-color;
    display:block;
    cursor: pointer;
  }

  .filter-name{
    display: block;
    flex: 1 1 auto;
  }

  .count {
    padding-left: 4px;
    padding-right: 4px;
  }
}

.no-animate {
   -webkit-transition: none !important;
   transition: none !important;
}
