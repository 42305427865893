.notice-content {
  .notice-head {
    z-index: 100;
    text-align: right;

    &.shadow {
      background-image: url(../../img/notice-head-bg.png);
      background-size: 90% 85px;
      background-repeat: no-repeat;
      background-position: top;
    }

    .notice-thumb {
      float: left;
      width: 120px;
      margin: 15px 0 0 30px;
      cursor: pointer;

      img {
        max-width: 120px;
      }
    }

    .publisher-links {
      margin-left: 10px;
      margin-top: 15px;
      float: left;
    }

    .notice-links {
      height: 76px;
      background-color: @white;
      padding-right: 4%;
      padding-top: 15px;
      right: 15px;

      .open-all {
        background-color: @notice-open-all-link-background;
        color: @notice-open-all-link-color;
        padding: 10px 12px 10px 12px;
        background-repeat: no-repeat;
        background-position: left;
        .border-radius(16px);
        font-size: 12px;
        display: block;
        float: left;

        i {
          color: @notice-open-all-link-color;
        }
      }
    }
  }
  .yellow-highlight {
    background: repeating-linear-gradient(45deg, #f5e5c4, #f5e5c4 5px, #eacb88 5px, #eacb88 10px);
    display: inline-block;
    line-height: 18px;
  }
}

.notice-scroll {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 45px;
  height: 90px;
  background-color: @notice-body-scroll-background;

  a {
    display: block;
    width: 45px;
    height: 45px;
    background-repeat: no-repeat;
    text-align: center;
  }
}

.content-place {
  margin-left: -15px;
  padding-left: 40px;
}

.related-resources {
  margin: 50px 0;
  .head {
    background-color: @notice-body-resources-background;
    padding: 12px 25px;
    position: relative;

    h2 {
      .RegularFont(40px);
      font-weight: 300;
      line-height: 1.1;
      color: @notice-body-resources-h2-color;
      margin: 0;
      padding: 0;
      float: left;
    }
  }

  a.see-all {
    .RegularFont(12px);
    .content-place;
    line-height: 40px;
    color: @notice-body-resources-see-all-color;

    i {
      display: inline-block;
      vertical-align: middle;
      color: @notice-body-resources-see-all-color;
    }
  }
}

.related-section {
  border-bottom: 4px solid @notice-body-resources-border;
  h3 {
    .BoldFont(20px);
    margin: 0;
    padding: 12px 25px;
    border-bottom: 4px solid @notice-body-resources-border;
  }

  a {
    word-wrap: break-word;
  }

  h4 {
    .RegularFont(20px);
    padding: 10px 25px;
    border-bottom: 1px solid @notice-body-resources-border;
    width: 50%;
    margin: 0;
  }

  .internal {
    ul {
      color: @notice-body-internal-list-color;
      list-style-type: circle;
      .content-place;
    }
    a {
      color: @notice-body-internal-link-color;
      background-color: @notice-body-internal-link-background;
      font-size: 18px;
      display: inline;
    }
    p {
      display: inline;
      margin: 0px;
      padding: 0px;
    }
  }

  .external {
    ul {
      color: @notice-body-external-color;
      list-style-type: circle;
      .content-place;
    }
    li {
      font-size: 16px;
    }
    p {
      margin: 0px;
      padding: 0px;
    }
    a {
      color: @notice-body-external-link-color;
      .RegularFont(16px);
    }
  }
}

.art-notice {
  .notice-head {
    .notice-links {
      border-left: 20px solid @notice-body-border-left;
    }
  }
  .notice-body-wrapper {
    border-left: 20px solid @notice-body-border-left;
    height: 100%;
    background: #fff;
  }
}

.artworks {
  .notice-scroll {
    display: none;
  }
  .notice-content {
    z-index: 100;
    padding: 0;
  }
}

#media-breakpoint-down(
  sm,
  {
    .notice-content {
      max-height: 100vh;
    }
    .art-notice {
      .notice-head {
        .notice-links {
          border-color: transparent;
        }
        .notice-thumb {
          display: none;
        }
      } 
      .notice-body {
        border: none;
        padding-right: 20px;
      }
    } 
    .notice-body {
      padding: 4% 2% 2% 2%;
    }
  }
);

#media-breakpoint-up(
  md,
  {
    .art-notice {
      .notice-content {
        height: 100%; 
        width: 100%; 
        padding: 0; 
        position: absolute; 
        padding: 0px; 
        z-index: 100; 
        overflow-y: hidden; 
        .notice-head {
          position: fixed; 
          right: 0px; 
          top: 0px; 
          border: 0px solid transparent;
        } 
        .notice-body {
          width: 100%;
        }
      }
    }
  }
);
