.tooltip {
  top: 0px;
  &.in {
    opacity: 100;
  }
}

.tooltip {
  pointer-events: none;
}

.tooltip-inner {
  max-width: 30vw;
  text-align: left;
}
.tooltip.autocomplete {
  margin-left: 10px;

  .tooltip-inner {
    color: white;
    border: 2px solid white;
    background-color: #323232;
    box-shadow: 6px 7px 20px 2px rgba(0, 0, 0, 0.075);
  }
  .tooltip-arrow {
    border-style: solid;
    border-width: 6.5px 8px 6.5px 0px;
    border-color: transparent #323232 transparent transparent;
  }
}
