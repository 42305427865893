.loader {
  background: rgba(0,0,0,0);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  z-index: -1;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -ms-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;

  &.show {
    z-index: 9999;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=0);
  }
}

.loader-icon {
  background-image: url('../img/loader_orange.gif');
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  display: block;
  height: 64px;
  width: 64px;
  left: 50%;
  top: 50%;
  margin-left: -14.5px;
  margin-top: -14.5px;
  -moz-background-size: 64px 64px;
  -o-background-size: 64px 64px;
  -webkit-background-size: 64px 64px;
  background-size: 64px 64px;
}
