.artwork-list {
  flex-direction: column;
  display: flex;
  padding-top: 25px;
  padding-bottom: 40px;
  width: 100%;
}

.art-items {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding-left: 0px;
  flex-grow: 1;
  
  .page {
    display: flex;
    flex-wrap: wrap;
  }
}

.select-all-moda-buttons {
  text-align: right;
}
