.top-tools-dropdown {
  position: absolute;
  left: 10px !important;
  right: 0 !important;
  top: 26px !important;
  z-index: 900;
  width: 234px;
  border: 0px;
  margin: 0px;
  padding: 0px;
  border-top: 5px solid @top-tools-dropdown-border;
  border-bottom: 5px solid @top-tools-dropdown-border;
  background-color: @top-tools-dropdown-background;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 10000;

  &::before {
    content: '';
    position: absolute;
    top: -12px;
    left: 205px;
    border-right: 7px solid transparent;
    border-bottom: 7px solid @top-tools-dropdown-border;
    border-left: 7px solid transparent;
  }

  & > ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    & > li {
      display: block;
      padding: 10px 7px 10px 10px;

      input {
        border-color: @top-tools-dropdown-input-border;
        border-top-width: 0.2rem;
        border-bottom-width: 0.2rem;
        border-left-width: 0.2rem;
        line-height: 1.2rem;
      }

      button {
        background-color: @top-tools-dropdown-input-button-bg;
        border-color: @top-tools-dropdown-input-button-border;
        color: @white;

        &:hover,
        &:focus {
          background-color: @top-tools-dropdown-input-button-bg;
          border-color: @top-tools-dropdown-input-button-border;
          color: @white;
          z-index: 0 !important;
        }
      }

      &:hover,
      .dropdown-item:hover,
      .dropdown-item:focus {
        background-color: @top-tools-dropdown-item-hover-first-child-background;
      }

      .dropdown-item:focus {
        background-color: transparent;
      }

      .dropdown-item {
        padding: 0;
        white-space: normal;

        &.btn {
          padding: 0.375rem 0.75rem;
          white-space: nowrap;
          text-align: center;

          &:hover {
            background-color: @top-tools-dropdown-item-hover-first-child-background;
            color: #2b2b2b;
          }
        }
      }

      &.dropdown-submenu:hover,
      &.dropdown-btn:hover {
        background-color: transparent;
      }

      &:last-child {
        border-bottom: none;
      }
      & > a {
        display: block;
      }
      a {
        color: @top-tools-dropdown-item-link-color;
      }
      .current-user {
        font-size: 16px;
        font-weight: 400;
        text-transform: none;
      }

      .cart-name {
        font-size: 14px;
        font-weight: bold;
        text-transform: none;
      }

      .cart-item i {
        color: @top-tools-favs-hover-color;
      }

      &.has-children {
        & > a {
          padding-right: 18px;
          background-position: right;
          background-repeat: no-repeat;
          background-image: url(../../img/icon-dropdown-has-children.png);
        }

        &.expanded {
          padding: 0;
          a.toggle-dropdown {
            padding: 10px;
          }
          li.cart-item {
            border-right: 3px solid transparent;
            border-top: 1px solid #d9d9d9;
            padding: 8px 5px 8px 27px;
            &.no-bullet {
              padding-left: 5px;
              font-weight: bold;
            }

            &:hover {
              background-color: @top-tools-dropdown-item-hover-background;
              border-right: 3px solid @top-tools-dropdown-item-hover-border-right;
            }
          }

          & > a {
            background-image: url(../../img/icon-dropdown-has-children-expanded.png);
          }

          &.hover {
            border-right: 3px solid @top-tools-dropdown-item-expanded-border-right;
          }

          .display-all {
            background-color: @top-tools-dropdown-item-display-all-background;
            padding: 0 15px;
            background-position: left;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      cursor: pointer;
      ul li {
        text-transform: none;

        &.cart-item:hover {
          background-color: @top-tools-dropdown-background;
        }
      }
    }

    .btn-link {
      &:hover {
        text-decoration: none;
      }
    }
  }
  ul.pager {
    display: flex;
    justify-content: space-between;

    li {
      margin: 6px 0;
      a {
        font-size: 12px;
        padding: 3px 6px;
        background-color: #ebcc89;
        border: 1px solid #777;
        border-radius: 15px;
        &:hover,
        &:focus {
          background-color: #ebcc89;
        }
        &.disabled {
          color: #777;
          background-color: #fff;
          cursor: not-allowed;
        }
      }
    }
  }
}

.help .dropdown-menu li,
.account .dropdown-menu li {
  border-top: 1px solid #d9d9d9;
}

.language-menu {
  right: 10px;
  left: -30px;
  min-width: 80px;
  margin-top: 0px;
  border: none;
  box-shadow: none;
  background-color: @chalky;

  & > li > a {
    color: white;
    border: none;
    display: inline-block;
    width: 100%;
    padding-left: 35px;

    &:hover {
      background-color: @mine-shaft;
      color: white;
    }
  }
}

.big-separator {
  display: block;
  box-sizing: border-box;
  position: relative;
  background: #adadad;
  border-color: #e5e5e5;
  border-style: solid;
  border-width: 4px 0;
  height: 9px;

  &:before,
  &:after {
    content: '';
    height: 1px;
    background: #adadad;
    position: absolute;
    left: 0;
    right: 0;
  }

  &:before {
    top: -2px;
  }
  &:after {
    bottom: -2px;
  }
}
