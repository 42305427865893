.scroll-always-visible > .ps__rail-x,
.scroll-always-visible > .ps__rail-y {
  opacity: 0.6;
}

.full-height {
  height: 100%;
}

.art {
  padding-bottom: 65px;
  position: relative;
  padding: 0;
}
