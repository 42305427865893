.RegularFont(@size: 13px, @weight: 400) {
  font-size: @size;
  font-family: Lato, Lucida, Verdana, Arial, Helvetica, sans-serif;
  font-weight: @weight;
}

.BoldFont(@size: 13px) {
  font-size: @size;
  font-family: Lato, Lucida, Verdana, Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.BlackFont(@size: 13px) {
  font-size: @size;
  font-family: Lato, Lucida, Verdana, Arial, Helvetica, sans-serif;
  font-weight: 900;
}

.HeaderFont(@size: 15px) {
  font-size: @size;
  font-family: Cambria, 'Liberation Serif', Times, 'Times New Roman', serif;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &::after {
    content: '';
    display: block;
  }
}

.clickable {
  cursor: pointer;
}

.vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}