.filter-group {
  & > ul {
    & > li {
      padding-left: 0px;
    }
  }
  ul {
    padding-left: 0px;
  }

  .pagination {
    .RegularFont(12px);
    padding: 34px 0 10px 0;
    color: @filter-pagination-color;
    width: 100%;
    text-align: center;
    margin: 0;
    line-height: 18px;
    display: flex;
    justify-content: center;

    strong {
      color: @filter-pagination-strong-color;
    }
    a {
      display: inline-block;
      width: 19px;
      height: 18px;
      line-height: 18px;
      vertical-align: top;
      text-align: center;
      cursor: pointer;

      &.prev {
        margin-right: 15px;
      }
      &.next {
        margin-left: 15px;
      }
    }
  }
}
