.carts-list,
.text-notes-list {
  padding-top: 25px;
  .RegularFont(14px);
  padding-right: 40px;
  padding-left: 40px;

  h1 {
    margin: 0 0 18px 0px;
    color: #000;
    .RegularFont(30px, 300);
  }

  .carts-bar {
    .btn-round {
      margin-left: 18px;
    }
  }

  .carts-list-error {
    margin-left: 30px;
    padding: 10px;
    color: black;
    .RegularFont(16px, 300);
  }

  .filter-input {
    width: 300px;
  }
  
  .table-sticky {
    height: 60vh;
    overflow-y: scroll;

    .col-width {
      max-width: 160px;
    }

    table {
      border-collapse: separate;
      border-spacing: 0;
    }

    th:before,
    th:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
    }

    th:after {
      bottom: -1px;
      border-bottom: 1px solid #d4d4d4;
    }
    
    th:before {
      top: 0px;
      border-top: 2px solid #757575;
    }
  }

  .table-sticky th {
    //position: -webkit-sticky;
    //position: sticky;
    //top: 0;
    //z-index: 2;
  }


  table {
    width: 100%;

    th:last-child, td:last-child { 
      border-right: none;
    }

    tr:nth-child(odd) {
      background-color: #f0f0f0;
    }

    tr:nth-child(even) {
      background-color: #d9d9d9;
    }

    thead {
      border-top-style: solid;
      border-top-color: #757575;
      border-bottom: 2px solid;
      border-bottom-color: #d4d4d4;

      th {
        .RegularFont(16px);
        font-weight: normal;
        border-right: 2px solid;
        border-color: #d4d4d4;
        line-height: normal;
        background-color: #fff;
        user-select: none;

        &.header-sort {
          cursor: pointer;
        }

        &.header-sort div:first-of-type::before {
          float: right;
          font-size: 8px;
        }

        &.header-sort:not(.sort-desc):not(.sort-asc) div:first-of-type::before {
          content: '\25B2';
          color: #d4d4d4;
          padding: 16px 0 16px 6px;
        }

        &.header-sort.sort-asc div:first-of-type::before,
        &.header-sort.sort-desc div:first-of-type::before {
          color: #000;
          padding: 16px 0;
        }

        &.header-sort.sort-asc div:first-of-type::before {
          content: '\25B2';
        }

        &.header-sort.sort-desc div:first-of-type::before {
          content: '\25BC';
        }
      }
    }

    td,
    th {
      border-right: 2px solid;
      border-color: #d4d4d4;
      padding: 12px 21px;
    }

    tbody {
      //height: 75vh;
      //display: block;
      //overflow-y: scroll;
    }

    thead,
    tbody tr {
      //display: table;
      //width: 100%;
      //table-layout: fixed;
    }

    thead {
      //width: calc(100% - 1em);
    }

    .col-linked .note-status {
      vertical-align: super;
    }

    .col-title {
      max-width: 400px;
    }

    .col-name {
      min-width: 300px;
    }

    .col-created {
      min-width: 140px;
    }

    .col-updated {
      min-width: 160px;
    }

    .col-length {
      width: 10% !important;
    }

    .col-actions {
      height: 57px;
      overflow: visible !important;
      
      i {
        cursor: pointer;
        font-size: 42px;
        vertical-align: middle;

        &.icon-vue_image_medium {
          margin: 0 8px;
          font-size: 28px;
        }

        &:hover {
          color: @chalky;
        }
      }
      position: relative;
    }
  }
}

.text-notes-list table tbody {
  //height: 50vh;
}

.send-cart {
  margin-left: -260px;
  display: flex;
  flex-direction: column;

  .error {
    font-weight: bold;
  }

  div {
    white-space: normal;
  }
}

.row-selected {
  background-color: @white  !important;
  border: 3px solid @chalky;

  .edit-cart-button {
    color: @chalky;
  }

  td {
    border-color: @white  !important;
  }
}

.row-marked {
  background-color: @white  !important;
  border: 3px solid @mine-shaft;

  td {
    border-color: @white  !important;
  }
}
